const PLURALS_FACTOR_1 = 1;
const PLURALS_FACTOR_2 = 2;
const PLURALS_FACTOR_5 = 5;

const getLastTwoDigits = number => number % 100;

const getLastDigit = number => number % 10;

export const getPluralsFactor = number => {
  const lastTwoDigits = getLastTwoDigits(number);

  if (lastTwoDigits === 0) return PLURALS_FACTOR_5;
  if (lastTwoDigits === 1) return PLURALS_FACTOR_1;
  if (lastTwoDigits >= 2 && lastTwoDigits <= 4) return PLURALS_FACTOR_2;
  if (lastTwoDigits >= 5 && lastTwoDigits <= 20) return PLURALS_FACTOR_5;
  if (lastTwoDigits > 20) return getPluralsFactor(getLastDigit(number));

  return PLURALS_FACTOR_5;
};

export const locales = ['ua', 'ru', 'en', 'es'];
