const getSyrveOrganisations = () => state => state.integration.syrve.organisations.data;
const getSyrveOrganisationsLoading = () => state => state.integration.syrve.organisations.isLoading;
const getSyrveOrganisationsError = () => state => state.integration.syrve.organisations.error;

const getSyrveTerminals = () => state => state.integration.syrve.terminals.data;
const getSyrveTerminalsLoading = () => state => state.integration.syrve.terminals.isLoading;
const getSyrveTerminalsError = () => state => state.integration.syrve.terminals.error;

const getPosterTerminals = () => state => state.integration.poster.terminals.data;
const getPosterTerminalsLoading = () => state => state.integration.poster.terminals.isLoading;
const getPosterTerminalsError = () => state => state.integration.poster.terminals.error;

export default {
  getSyrveOrganisations,
  getSyrveOrganisationsLoading,
  getSyrveOrganisationsError,
  getSyrveTerminals,
  getSyrveTerminalsLoading,
  getSyrveTerminalsError,

  getPosterTerminals,
  getPosterTerminalsLoading,
  getPosterTerminalsError
};
