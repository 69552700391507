import React, { memo } from 'react';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import mova from 'mova';

import Card from '@components/Card/Card';
import Typography from '@mui/material/Typography';
import FormikTextInput from '@form/FormikTextInput';
import Box from '@mui/material/Box';
import Button from '@components/Button/Button';
import FormikCheckbox from '@form/FormikCheckbox';
import FormikSelect from '@form/FormikSelect';
import store from '@state/store';
import { placeThunks } from 'state/ducks/place';
import Grid from '@mui/material/Grid';
import { PHONE_REGEX } from "utils/utils";

const t = mova.ns('components.Settings');

const schema = Yup.object().shape({
  id: Yup.number(),
  name: Yup.string().required(() => t('errors.required')),
  description: Yup.string(),
  cityId: Yup.number().required(() => t('errors.required')),
  address: Yup.string().required(() => t('errors.required')),
  location: Yup.object().shape({
    coordinates: Yup.array().length(2).of(Yup.number(() => t('errors.number'))),
  }),
  active: Yup.boolean(),
  phone: Yup.string()
    .required(() => t('errors.required'))
    .matches(PHONE_REGEX, () => t('errors.phone')),
});

const Description = ({ cities }) => {
  return (
    <Card>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography>{t('fields.name')}</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormikTextInput name='name' fullWidth placeholder={t('fields.name')} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography>{t('fields.description')}</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormikTextInput
              inputProps={{ style: { padding: 0 } }}
              name='description'
              multiline
              fullWidth
              placeholder={t('fields.description')}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography>{t('fields.city')}</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormikSelect fullWidth name='cityId' items={cities} valuePath='id' labelPath='tr' />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography>{t('fields.address')}</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormikTextInput name='address' fullWidth placeholder={t('fields.address')} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography>{t('fields.contactPhone')}</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormikTextInput name='phone' fullWidth placeholder={t('fields.contactPhone')} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography>{t('fields.latitude')}</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormikTextInput name='location.coordinates[0]' fullWidth placeholder={t('fields.latitude')} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography>{t('fields.longitude')}</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormikTextInput name='location.coordinates[1]' fullWidth placeholder={t('fields.longitude')} />
          </Grid>
          <Grid item xs={12}>
            <FormikCheckbox name='active' label={t('fields.active')} />
          </Grid>
        </Grid>
        <Box display='flex' justifyContent='center'>
          <Button type='submit' size='large'>
            {t('save')}
          </Button>
        </Box>
      </Form>
    </Card>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ place }) => ({
      id: place.id,
      name: place?.name || '',
      description: place?.description || '',
      cityId: place?.cityId || 1,
      address: place?.address || '',
      location: {
        ...(place?.location || {}),
        coordinates: [place?.location?.coordinates[0] || 0, place?.location?.coordinates[1] || 0],
      },
      active: place?.active || false,
      phone: place?.phone || '',
    }),
    validationSchema: schema,
    handleSubmit: async (data, helpers) => {
      await store.dispatch(placeThunks.updatePlace(data));
      helpers.props.showToastr(t('messages.saved'));
      helpers.setSubmitting(false);
    },
  })(Description),
);
