import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import mova from 'mova';
import { io as socketIOClient } from 'socket.io-client';

import config from 'config';
import history from 'history.js';
import { bind, debounce } from 'utils/decorators';
import jwtService from '../../services/jwtService';
import { toastrActions } from 'state/ducks/toastr';
import { userActions, userThunks } from 'state/ducks/user';
import { placeSelectors } from 'state/ducks/place';
import { reservationActions, reservationSelectors } from 'state/ducks/reservation';
import Loader from '@components/Loader/Loader';
import userSelectors from '@state/ducks/user/selectors';

const t = mova.ns('service.jwt');

const initSocketConnection = () =>
  socketIOClient(`${config.apiDirect}/`, {
    transports: ['websocket'],
    // autoConnect: false,
    timeout: 60000,
    pingTimout: 60000,
    rejectUnauthorized: false,
    allowUpgrades: false,
  });

class Auth extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      waitAuthCheck: true,
    };
    this.socket = initSocketConnection();
    this.accessToken = null;
    this.connectSocketSub();
  }

  componentDidMount() {
    return Promise.all([this.jwtCheck()]).then(() => {
      this.setState({ waitAuthCheck: false });
    });
  }

  componentWillUnmount() {
    this.socket.off('get_data');
  }

  connectSocketSub = () => {
    this.socket.on('connect', () => {
      console.log('[ SOCKET.IO - connected ]'); // eslint-disable-line no-console
    }); // eslint-disable-line no-console
    this.socket.on('disconnect', this.onDisconnect);
  };

  @bind
  @debounce(10000)
  onDisconnect() {
    this.socket = initSocketConnection();
    this.connectSocketSub();
    this.subscribeOnSocket();
    console.log('SOCKET.IO - disconnected'); // eslint-disable-line no-console
  }

  subscribeOnSocket = () => {
    const createOrUpdateReservation = (placeId, reservation) => {
      const { incrementReservationsKey, newReservations, setNewReservations, addOrReplaceHallReservation } = this.props;
      incrementReservationsKey();
      addOrReplaceHallReservation(reservation);
      if (reservation.status === 'REQUESTED') {
        setNewReservations(newReservations + 1);
      }
    };
    this.socket.emit('initial', { accessToken: this.accessToken });
    this.socket.on('new_notification', data => {
      process.env.NODE_ENV !== 'production' && console.log('[SOCKET:] new_notification', data); // eslint-disable-line no-console
      // debounceFunc(this.props.getNotices, 1000)(data);
      // const { activePlace } = this.props;
      // if (activePlace.id === data?.place?.id) {
      //   debounceFunc(updateOutdatedReservation, 3000)(activePlace.id);
      // }
    });
    
    this.socket.on('changed_reservation', reservation => {
      process.env.NODE_ENV !== 'production' && console.log('[SOCKET:] changed_reservation', reservation); // eslint-disable-line no-console
      const { activePlace } = this.props;
      if (activePlace?.id === reservation?.placeId) {
        createOrUpdateReservation(activePlace.id, reservation);
      }
    });

    this.socket.on('new_reservation', reservation => {
      process.env.NODE_ENV !== 'production' && console.log('[SOCKET:] new_reservation', reservation); // eslint-disable-line no-console
      const { activePlace } = this.props;
      if (activePlace?.id === reservation?.placeId) {
        createOrUpdateReservation(activePlace.id, reservation);
      }
    });
  };

  jwtCheck = () =>
    new Promise(resolve => {
      jwtService.on('onAutoLogin', () => {
        jwtService
          .signInWithToken(this.props.getProfile)
          .then(response => {
            this.props.setUserData(response.payload);
            resolve(response);
            this.props.showToastr({ message: t('welcomeMessage') });
            return response;
          })
          .catch(err => {
            resolve(err);
            this.props.showToastr({ message: t('loginWithTokenFailed') });
            return err;
          });
      });

      jwtService.on('onAutoLogout', message => {
        this.props.logout();
        resolve();
        if (message) {
          this.props.showToastr({ message });
        }
      });
      jwtService.on('onLogOut', () => {
        this.props.logout();
        this.socket.emit('logout');
        history.push('/login');
        resolve();
      });

      jwtService.on('connectSocket', accessToken => {
        if (accessToken) {
          this.accessToken = accessToken;
          this.subscribeOnSocket();
        }
      });

      jwtService.on('onNoAccessToken', () => {
        resolve();
      });

      jwtService.init();
    });

  render() {
    return this.state.waitAuthCheck || this.props.userLoading ? <Loader fullScreen /> : this.props.children;
  }
}

const mapStateToProps = state => ({
  userLoading: userSelectors.isUserLoading()(state),
  activePlace: placeSelectors.getActivePlace()(state),
  newReservations: reservationSelectors.getNewReservations()(state),
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getProfile: userThunks.getProfile,
      setUserData: userActions.setUserData,

      logout: userActions.logOutUser,
      showToastr: toastrActions.showToastr,
      getNotices: userThunks.getNotices,
      // setNewNotices: Actions.setNewNotices,
      incrementReservationsKey: reservationActions.incrementReservationsKey,
      setNewReservations: reservationActions.setNewReservations,
      addOrReplaceHallReservation: reservationActions.addOrReplaceHallReservation,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
