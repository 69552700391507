import React, { memo } from 'react';
import { IconButton, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import localStorage from 'utils/localStorage';
import { userSelectors, userThunks } from 'state/ducks/user';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';

const StyledIconButton = styled(IconButton, { shouldForwardProp: prop => prop !== 'black' })(({ theme, active, black }) => ({
  color: active && !black ? theme.palette.common.white : theme.palette.typography.blurred,
  fontSize: 16,
}));

const LanguageSelector = ({ black, ...rest }) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.getCurrentUser());
  const theme = useTheme();

  const handleUpdateLang = lang => async () => {
    await dispatch(userThunks.updateLang({ lang }));
    localStorage.set(localStorage.Keys.LANG, lang);
    window.location.reload(false);
  };

  const currentLang = user?.lang || localStorage.get(localStorage.Keys.LANG);

  return (
    <Box color={theme.palette.typography.blurred} {...rest}>
      <StyledIconButton onClick={handleUpdateLang('es')} active={currentLang === 'es' ? 1 : 0} black>
        ES
      </StyledIconButton>{' '}
      /
      <StyledIconButton onClick={handleUpdateLang('ua')} active={currentLang === 'ua' ? 1 : 0} black>
        UA
      </StyledIconButton>{' '}
      /
      <StyledIconButton onClick={handleUpdateLang('en')} active={currentLang === 'en' ? 1 : 0} black>
        EN
      </StyledIconButton>
    </Box>
  );
};

export default memo(LanguageSelector);
