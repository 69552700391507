export default {
  apiDirect:
    {
      stage: 'http://localhost:4011',
      production: 'https://reservble.com',
    }[process.env.NODE_ENV] || 'http://localhost:3011',
  clientUiUrl:
    {
      stage: 'https://stage-guest.reservble.com',
      production: 'https://reservble.com',
    }[process.env.NODE_ENV] || 'http://localhost:8080',
  restUiUrl:
    {
      stage: 'https://stage-admin.reservble.com',
      production: 'https://resto.reservble.com',
    }[process.env.NODE_ENV] || 'http://localhost:3000',
  apiRoot: '/api/v1',
  filesRoot: '/files',
  googleApiKey: 'AIzaSyCl4Ji7FJ2Ms_1zuYqWJOubtxpBVIp9EQ4',
  googleApi: 'https://maps.google.com/maps/api/geocode/json',
  integrations: {
    jp: {
      redirectUri: `${window.location.origin}/oauth/joinposter`,
      applicationId: process.env.NODE_ENV === 'production' ? 656 : 797,
    },
  },
  defaultLang: 'en',
};
