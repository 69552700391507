import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'state/utils/loadDataExtraReducer';
import loadDataInitialState from 'state/utils/loadDataInitialState';
import localStorage from 'utils/localStorage';
import { get } from 'utils/lo/lo';

const baseReviewsState = { rows: [], count: 0, page: 1 };

const placeSlice = createSlice({
  name: 'place',
  initialState: {
    list: loadDataInitialState([]),
    fullList: loadDataInitialState({ rows: [], count: 0, page: 1 }),
    details: loadDataInitialState(null, false),
    openHours: loadDataInitialState([]),
    photos: loadDataInitialState([]),
    contacts: loadDataInitialState([]),
    managers: loadDataInitialState([]),
    donations: loadDataInitialState([]),
    activePlace: null,
    placeStats: loadDataInitialState({ total: null, reservations: { online: 0, our: 0, total: 0 } }),
    client: loadDataInitialState(null),
    reviews: loadDataInitialState({
      reservble: baseReviewsState,
      google: baseReviewsState,
      facebook: baseReviewsState,
    }),
    reviewStats: loadDataInitialState({ avgRating: 0, total: 0 }),
    reviewGoogleStats: loadDataInitialState(null),
    statsVisits: loadDataInitialState({ visitsAll: 0, visitsRegular: 0 }),
    clientHistory: loadDataInitialState({ rows: [], count: 0 }),
    customTags: loadDataInitialState({}),
    deposits: loadDataInitialState([]),
    paidServices: loadDataInitialState({}),
    certificates: loadDataInitialState([]),
  },
  reducers: {
    setActivePlace: {
      reducer: (state, action) => {
        localStorage.set(localStorage.Keys.ACTIVE_PLACE, action.payload);
        state.activePlace = action.payload;
      },
      prepare: payload => ({ payload }),
    },
  },
  extraReducers: {
    ...loadDataExtraReducer(thunks.fetchAllPlaces, 'fullList'),

    ...loadDataExtraReducer(thunks.fetchPlaces, 'list'),
    ...loadDataExtraReducer(thunks.addPlace, 'list'),
    ...loadDataExtraReducer(thunks.deletePlace, 'list'),

    ...loadDataExtraReducer(thunks.updatePlace, 'details'),
    ...loadDataExtraReducer(thunks.updatePlaceBusyHours, 'details'),
    ...loadDataExtraReducer(thunks.updatePlaceDonates, 'details'),
    ...loadDataExtraReducer(thunks.updatePlaceConnections, 'details'),
    ...loadDataExtraReducer(thunks.saveReservationUi, 'details'),
    ...loadDataExtraReducer(thunks.fetchPlace, 'details'),
    ...loadDataExtraReducer(thunks.updateAvatar, 'details'),
    ...loadDataExtraReducer(thunks.addPosterConnection, 'details'),
    ...loadDataExtraReducer(thunks.savePosterConnection, 'details'),
    ...loadDataExtraReducer(thunks.syncPosterTables, 'details'),

    ...loadDataExtraReducer(thunks.fetchOpenHour, 'openHours'),
    ...loadDataExtraReducer(thunks.addOpenHour, 'openHours'),
    ...loadDataExtraReducer(thunks.updateOpenHour, 'openHours'),

    ...loadDataExtraReducer(thunks.fetchPhotos, 'photos'),
    ...loadDataExtraReducer(thunks.addPhoto, 'photos'),
    ...loadDataExtraReducer(thunks.deletePhoto, 'photos'),

    ...loadDataExtraReducer(thunks.fetchContacts, 'contacts'),
    ...loadDataExtraReducer(thunks.addContact, 'contacts'),
    ...loadDataExtraReducer(thunks.updateContact, 'contacts'),
    ...loadDataExtraReducer(thunks.deleteContact, 'contacts'),

    ...loadDataExtraReducer(thunks.fetchManagers, 'managers'),
    ...loadDataExtraReducer(thunks.addManager, 'managers'),
    ...loadDataExtraReducer(thunks.updateManager, 'managers'),
    ...loadDataExtraReducer(thunks.deleteManager, 'managers'),

    ...loadDataExtraReducer(thunks.fetchPlaceStats, 'placeStats'),

    ...loadDataExtraReducer(thunks.fetchPlaceClient, 'client'),

    ...loadDataExtraReducer(thunks.fetchReviews, 'reviews', {
      fulfilled: (state, action) => {
        get(state, 'reviews').isLoading = false;
        get(state, 'reviews').data[action.payload.type] = action.payload;
        get(state, 'reviews').error = null;
      },
    }),
    ...loadDataExtraReducer(thunks.fetchReviewStats, 'reviewStats'),
    ...loadDataExtraReducer(thunks.fetchReviewGoogleStats, 'reviewGoogleStats'),

    ...loadDataExtraReducer(thunks.fetchPlaceStatsVisits, 'statsVisits'),

    ...loadDataExtraReducer(thunks.fetchDonations, 'donations'),

    ...loadDataExtraReducer(thunks.fetchClientHistory, 'clientHistory'),

    ...loadDataExtraReducer(thunks.fetchCustomTags, 'customTags'),
    ...loadDataExtraReducer(thunks.saveCustomTags, 'customTags'),

    ...loadDataExtraReducer(thunks.fetchDeposits, 'deposits'),
    ...loadDataExtraReducer(thunks.saveDeposit, 'deposits'),
    ...loadDataExtraReducer(thunks.deleteDeposit, 'deposits'),
    ...loadDataExtraReducer(thunks.deleteDepositType, 'deposits'),

    ...loadDataExtraReducer(thunks.fetchPaidServices, 'paidServices'),
    ...loadDataExtraReducer(thunks.savePaidServices, 'paidServices'),
    ...loadDataExtraReducer(thunks.addPaidServicesPhoto, 'paidServices'),
    ...loadDataExtraReducer(thunks.deletePaidServicesPhoto, 'paidServices'),

    ...loadDataExtraReducer(thunks.fetchCertificates, 'certificates'),
    ...loadDataExtraReducer(thunks.saveCertificate, 'certificates'),
    ...loadDataExtraReducer(thunks.deleteCertificate, 'certificates'),
  },
});

export const { setActivePlace } = placeSlice.actions;

export default placeSlice.reducer;
